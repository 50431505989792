import React, { useMemo } from 'react';
import { Token } from 'models/token';
import useTokens from 'hooks/useTokens';

interface Props {
  tokens: string[];
}

export default function PoolTokens({ tokens }: Props) {
  const { getTokenByAddress } = useTokens();
  const poolTokens: Token[] = useMemo(
    () => tokens.map(getTokenByAddress).filter(Boolean),
    [tokens, getTokenByAddress],
  ) as Token[];

  return (
    <div className="flex flex-wrap">
      {poolTokens.map((token: Token) => (
        <div key={`${token.id}`} className="py-1 px-2 mb-2 mr-2 font-semibold rounded-md poly-bg">
          {token.symbol}
        </div>
      ))}
    </div>
  );
}
