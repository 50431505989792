import {
  useContext,
  useEffect,
  useState,
} from 'react';
import { ethers } from 'ethers';
import { Pool } from 'models/pool';
import { WalletContext } from 'contexts/wallet';

export default function usePools() {
  const { getContract } = useContext(WalletContext);
  const [pools, setPools] = useState<Pool[]>([]);
  const SwapPoolFactory = getContract('SwapPoolFactory');

  const refetchPools = async () => {
    if (SwapPoolFactory && !pools.length) {
      const events = await SwapPoolFactory?.queryFilter({});
      const pools = events?.map((event) => {
        const { transactionHash, data, topics } = event;
        let tokens: string[] = [];
        if (event?.decode) {
          const decodedData = event?.decode(data);
          // eslint-disable-next-line no-underscore-dangle
          tokens = decodedData._assets;
        }
        const poolAddress = ethers.utils.hexStripZeros(topics[2]);
        return {
          id: poolAddress,
          txId: transactionHash,
          tokens,
        };
      });
      setPools(pools);
    }
  };

  useEffect(() => {
    refetchPools();
  }, [SwapPoolFactory, pools]);

  return {
    pools,
    refetchPools,
  };
}
