import React, {
  createContext,
  ReactChild,
  useCallback,
  useMemo,
  useState,
} from 'react';
import AssetDialog from 'components/AssetDialog';
import { Entity } from 'models/entity';

interface ProviderProps {
  children: ReactChild;
}

interface AssetDialogProps {
  open: boolean;
  items: Entity[];
  title: string;
  type: 'chain' | 'token';
  onSelect: (entity: Entity) => void;
}

export const AssetDialogContext = createContext({
  // eslint-disable-next-line no-unused-vars
  openDialog: (props: Partial<AssetDialogProps>) => {},
  closeDialog: () => {},
});

export default function AssetDialogProvider({ children }: ProviderProps) {
  const items: Entity[] = [];
  const [dialogProps, setDialogProps] = useState<AssetDialogProps>(
    { open: false, items } as AssetDialogProps,
  );

  const openDialog = useCallback((props: Partial<AssetDialogProps>): void => {
    setDialogProps((oldProps) => ({
      ...oldProps,
      ...props,
      open: true,
    }));
  }, []);

  const closeDialog = useCallback((): void => {
    setDialogProps((props) => ({ ...props, open: false }));
  }, []);

  const values = useMemo(() => ({ openDialog, closeDialog }), [openDialog, closeDialog]);

  return (
    <AssetDialogContext.Provider
      value={values}
    >
      <AssetDialog
        {...dialogProps}
        onClose={closeDialog}
      />
      {children}
    </AssetDialogContext.Provider>
  );
}
