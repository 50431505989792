import React, {
  useState,
  createContext,
  ReactChild,
  useMemo,
  useCallback,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

interface ProviderProps {
  children: ReactChild;
}

export const NotificationContext = createContext({
  showNotification: (err: any) => {},
  hideNotification: (err: any) => {},
});

export default function NotificationProvider({ children }: ProviderProps) {
  const DEFAULT_NOTIFICATION_PROPS = {
    elevation: 6,
    variant: 'filled',
    severity: 'error',
    message: 'Demo Notification',
    anchorOrigin: { vertical: 'top', horizontal: 'center' },
    autoHideDuration: 5000,
  };

  const [isNotificationVisible, setNotificationVisibility] = useState(false);
  const [notificationProps, setNotificationProps] = useState<any>(DEFAULT_NOTIFICATION_PROPS);

  const hideNotification = useCallback(() => setNotificationVisibility(false), []);

  const showNotification = useCallback((providedProps: any) => {
    setNotificationProps({
      ...DEFAULT_NOTIFICATION_PROPS,
      onClose: hideNotification,
      ...providedProps,
    });
    setNotificationVisibility(true);
  }, [DEFAULT_NOTIFICATION_PROPS]);

  const values = useMemo(() => ({
    showNotification,
    hideNotification,
  }), [showNotification, notificationProps]);

  return (
    <NotificationContext.Provider value={values}>
      <Snackbar
        open={isNotificationVisible}
        onClose={() => notificationProps.autoHideDuration && hideNotification()}
        anchorOrigin={notificationProps.anchorOrigin}
        autoHideDuration={notificationProps.autoHideDuration}
      >
        <MuiAlert
          elevation={notificationProps.elevation}
          severity={notificationProps.severity}
          variant={notificationProps.variant}
          onClose={notificationProps.showCloseOption ? notificationProps.onClose : undefined}
        >
          {notificationProps.message}
        </MuiAlert>
      </Snackbar>

      {children}
    </NotificationContext.Provider>
  );
}
