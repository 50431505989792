import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AreaChart,
  Area,
  Tooltip,
  ResponsiveContainer,
// eslint-disable-next-line import/no-unresolved
} from 'recharts';

import { SwapVert } from '@mui/icons-material';
import { Token } from '../../models/token';
import { chains } from '../../mock';
import AssetCard from './components/AssetCard';
import { WalletContext } from '../../contexts/wallet';
import Button from '../../components/Button';
import { NotificationContext } from '../../contexts/notification';
import useSwap, { IAsset } from './hooks/useSwap';
import useTokens from '../../hooks/useTokens';
import { convertsStrToDecimal } from '../../utils/helper';
import useDebounce from '../../hooks/useDebounce';
import StyledSelectButton from './components/SelectButton';
import useErc20 from '../../hooks/useErc20';

const data1 = [
  {
    name: 'Jan 2019',
    product: 3432,
  },
  {
    name: 'Feb 2019',
    product: 2000,
  },
  {
    name: 'Mar 2019',
    product: 2500,
  },
  {
    name: 'Apr 2019',
    product: 3600,
  },
  {
    name: 'May 2019',
    product: 8765,
  },
  {
    name: 'Apr 2019',
    product: 2300,
  },
  {
    name: 'May 2019',
    product: 4000,
  },
  {
    name: 'Jan 2019',
    product: 8002,
  },
  {
    name: 'Feb 2019',
    product: 3000,
  },
  {
    name: 'Mar 2019',
    product: 4034,
  },
  {
    name: 'Apr 2019',
    product: 2300,
  },
  {
    name: 'May 2019',
    product: 4000,
  },
  {
    name: 'Apr 2019',
    product: 5000,
  },
  {
    name: 'May 2019',
    product: 4000,
  },
  {
    name: 'Jan 2019',
    product: 2002,
  },
  {
    name: 'Feb 2019',
    product: 3000,
  },
  {
    name: 'Mar 2019',
    product: 4034,
  },
  {
    name: 'Apr 2019',
    product: 2300,
  },
  {
    name: 'May 2019',
    product: 8000,
  },
  {
    name: 'Apr 2019',
    product: 2300,
  },
  {
    name: 'May 2019',
    product: 4000,
  },
  {
    name: 'Jan 2019',
    product: 6002,
  },
  {
    name: 'Feb 2019',
    product: 3000,
  },
  {
    name: 'Mar 2019',
    product: 4034,
  },
  {
    name: 'Apr 2019',
    product: 2300,
  },
  {
    name: 'May 2019',
    product: 4000,
  },
  {
    name: 'Apr 2019',
    product: 2300,
  },
  {
    name: 'May 2019',
    product: 6500,
  },
  {
    name: 'Jan 2019',
    product: 2002,
  },
  {
    name: 'Feb 2019',
    product: 3000,
  },
  {
    name: 'Mar 2019',
    product: 4034,
  },
  {
    name: 'Apr 2019',
    product: 2300,
  },
  {
    name: 'May 2019',
    product: 4000,
  },
];

const data2 = [
  {
    name: 'Jan 2019',
    product: 2002,
  },
  {
    name: 'Feb 2019',
    product: 3000,
  },
  {
    name: 'Mar 2019',
    product: 4034,
  },
  {
    name: 'Apr 2019',
    product: 2300,
  },
  {
    name: 'May 2019',
    product: 4000,
  },
  {
    name: 'Jan 2019',
    product: 2002,
  },
  {
    name: 'Feb 2019',
    product: 3000,
  },
  {
    name: 'Mar 2019',
    product: 4034,
  },
  {
    name: 'Apr 2019',
    product: 2300,
  },
  {
    name: 'May 2019',
    product: 4000,
  },
  {
    name: 'Jan 2019',
    product: 2002,
  },
  {
    name: 'Feb 2019',
    product: 3000,
  },
  {
    name: 'Mar 2019',
    product: 4034,
  },
  {
    name: 'Apr 2019',
    product: 2300,
  },
  {
    name: 'May 2019',
    product: 4000,
  },
  {
    name: 'Jan 2019',
    product: 2002,
  },
  {
    name: 'Feb 2019',
    product: 3000,
  },
  {
    name: 'Mar 2019',
    product: 4034,
  },
  {
    name: 'Apr 2019',
    product: 2300,
  },
  {
    name: 'May 2019',
    product: 4000,
  },
];

function CustomTooltip({ active, payload }: any) {
  if (active && payload && payload.length) {
    const { name, product } = payload[0].payload;
    return (
      <div className="bg-slate-300 rounded-md text-sm p-2 font-medium dark:text-black">
        <span>{`${name} | ${product}`}</span>
      </div>
    );
  }
  return null;
}

export default function Swap() {
  const {
    connect,
    connected,
    connecting,
  } = useContext(WalletContext);
  const { showNotification } = useContext(NotificationContext);
  const { chainsMapping, getTokensByChain } = useTokens();
  const [fromAsset, setFromAsset] = useState<IAsset>({
    chain: chains[0],
    token: undefined,
    amount: '',
  });
  const [toAsset, setToAsset] = useState<IAsset>({
    chain: chains[0],
    token: undefined,
    amount: '',
  });
  const {
    pool,
    pools,
    drySwap,
    assetSwap,
    resetBestPool,
  } = useSwap();
  const {
    allowance,
    approveAmount,
    fetchAllowance,
  } = useErc20(fromAsset.token);
  const [fetchingPool, setFetchingPool] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [graphData, setGraphData] = useState(data1);

  const skipFirstConnect = useRef(true);

  const { chain: fromChain, token: fromToken, amount: fromAmount } = fromAsset;
  const { chain: toChain, token: toToken, amount: toAmount } = toAsset;
  const debouncedAmount = useDebounce<string>(fromAmount, 400);

  const hasAllowance = useMemo(() => {
    const minAllowanceReqd = +(fromAmount || '0');
    return allowance >= minAllowanceReqd;
  }, [allowance, fromAmount]);

  const fromTokensList = useMemo(() => {
    const { chain: fromChain } = fromAsset;
    const { chain: toChain, token: toToken } = toAsset;
    const tokens = getTokensByChain(fromChain.chainId);
    if (fromChain.id !== toChain.id) {
      return tokens;
    }
    return tokens.filter((token: Token) => token.id !== toToken?.id);
  }, [fromAsset.chain, toAsset.chain, toAsset.token, getTokensByChain]);

  const toTokensList = useMemo(() => {
    const { chain: fromChain, token: fromToken } = fromAsset;
    const { chain: toChain } = toAsset;
    const tokens = getTokensByChain(toChain.chainId);
    if (fromChain.id !== toChain.id) {
      return tokens;
    }
    return tokens.filter((token: Token) => token.id !== fromToken?.id);
  }, [fromAsset.chain, fromAsset.token, toAsset.chain, getTokensByChain]);

  const handleApproval = async () => {
    setLoading(true);
    try {
      await approveAmount(fromAmount, pool?.id ?? '');
    } finally {
      setLoading(false);
    }
  };

  const replaceSwapSelection = () => {
    setFromAsset((asset: IAsset) => ({
      ...asset,
      chain: toAsset.chain,
      token: toAsset.token,
      amount: toAsset.amount,
    }));
    setToAsset((asset: IAsset) => ({
      ...asset,
      chain: fromAsset.chain,
      token: fromAsset.token,
      amount: '',
    }));
  };

  const handleAssetUpdate = (setter: Dispatch<SetStateAction<IAsset>>, key: string) => (
    (value: any) => {
      setter((asset: IAsset) => ({
        ...asset,
        [key]: value,
      }));
    }
  );

  const handleSwap = async () => {
    const isLocalSwap = fromChain?.id === toChain?.id;
    if (+fromAmount > 0 && +toAmount > 0) {
      setLoading(true);
      try {
        const isSuccess = await assetSwap({
          isLocalSwap,
          fromAsset: { chain: fromChain, token: fromToken, amount: fromAmount },
          toAsset: { chain: toChain, token: toToken },
        } as any);
        if (isSuccess) {
          const resetAssets = (asset: IAsset) => ({
            ...asset,
            amount: '',
          });
          setToAsset(resetAssets);
          setFromAsset(resetAssets);
          await fetchAllowance(pool?.id ?? '');
        }
      } catch (err: any) {
        showNotification({
          message: `Assets swapped failed due to ${err?.reason}`,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const connectWalletToChain = (chainId: number = fromChain?.chainId) => {
    connect(chainId).catch((err) => showNotification({ message: err }));
  };

  // @ts-ignore
  useEffect(async () => {
    const amount = convertsStrToDecimal(debouncedAmount, fromToken?.decimals).toString();
    if (fromChain && fromToken && debouncedAmount && toChain && toToken) {
      const isLocalSwap = fromChain?.id === toChain?.id;
      const val = await drySwap({
        isLocalSwap,
        fromAsset: { chain: fromChain, token: fromToken, amount },
        toAsset: { chain: toChain, token: toToken, amount: '' },
      });
      setLoading(false);
      const formattedNum = (+val).toFixed(4);
      handleAssetUpdate(setToAsset, 'amount')(formattedNum);
    }
  }, [
    fromAsset.chain,
    fromAsset.token,
    debouncedAmount,
    toAsset.chain,
    toAsset.token,
  ]);

  useEffect(() => {
    if (chainsMapping && Object.keys(chainsMapping).length) {
      const [fromToken] = getTokensByChain(fromChain.chainId);
      handleAssetUpdate(setFromAsset, 'token')(fromToken);
      const toTokens: Token[] = getTokensByChain(toChain.chainId);
      if (fromChain.chainId !== toChain.chainId) {
        handleAssetUpdate(setToAsset, 'token')(toTokens[0]);
      } else {
        handleAssetUpdate(setToAsset, 'token')(toTokens[1]);
      }
    }
  }, [
    chainsMapping,
    getTokensByChain,
    fromChain,
    toChain,
  ]);

  useEffect(() => {
    if (fromChain && connected) {
      if (skipFirstConnect.current) {
        skipFirstConnect.current = false;
        return;
      }
      connectWalletToChain(fromChain?.chainId);
    }
  }, [
    fromChain,
    connected,
    skipFirstConnect,
  ]);

  useEffect(() => {
    if (fromChain && fromToken && toChain && toToken) {
      resetBestPool();
    }
  }, [
    fromChain,
    fromToken,
    toChain,
    toToken,
  ]);

  // @ts-ignore
  useEffect(async () => {
    if (
      !fetchingPool && !pool && pools.length
      && fromChain && fromToken && !debouncedAmount
      && toChain && toToken
    ) {
      const isLocalSwap = fromChain?.id === toChain?.id;
      const testAmountToFindBestPool = '1000000000000000000';
      const amount = convertsStrToDecimal(testAmountToFindBestPool, fromToken?.decimals).toString();
      setFetchingPool(true);
      await drySwap({
        isLocalSwap,
        fromAsset: { chain: fromChain, token: fromToken, amount },
        toAsset: { chain: toChain, token: toToken, amount: '' },
      });
      setFetchingPool(false);
    }
  }, [
    fetchingPool,
    pools,
    fromChain,
    fromToken,
    debouncedAmount,
    toChain,
    toToken,
  ]);

  // @ts-ignore
  useEffect(async () => {
    if (pool?.id) {
      await fetchAllowance(pool.id);
    }
  }, [pool]);

  return (
    <div className="flex flex-col p-3 space-x-3 sm:flex-row sm:p-5 sm:space-x-5">
      <div className="card flex flex-col items-center p-6 space-y-5 w-auto sm:space-y-8 sm:mx-auto sm:basis-[30rem]">
        <div className="flex flex-col">
          <div className="flex flex-row justify-start">
            <span className="flex items-center text-base">From</span>
            <StyledSelectButton
              type="chain"
              options={chains}
              className="ml-2 w-auto bg-slate-100 shadow-inner text-base"
              item={fromChain}
              onChange={handleAssetUpdate(setFromAsset, 'chain')}
              dialogTitle="Select Source Chain"
            />
          </div>
          <AssetCard
            className="mt-2"
            {...fromAsset}
            sourceAsset
            tokensList={fromTokensList}
            onTokenChange={handleAssetUpdate(setFromAsset, 'token')}
            onAmountChange={handleAssetUpdate(setFromAsset, 'amount')}
          />
        </div>
        <Button
          className="bg-slate-100 shadow-inner w-max p-1 rounded-md dark:bg-base-dark"
          onClick={replaceSwapSelection}
        >
          <SwapVert className="poly-sec-text dark:text-white" />
        </Button>
        <div className="flex flex-col">
          <div className="flex flex-row justify-start">
            <span className="flex items-center text-base">To</span>
            <StyledSelectButton
              type="chain"
              options={chains}
              className="ml-6 w-auto bg-slate-100 shadow-inner text-base"
              item={toChain}
              onChange={handleAssetUpdate(setToAsset, 'chain')}
              dialogTitle="Select Destination Chain"
            />
          </div>
          <AssetCard
            className="mt-2"
            {...toAsset}
            tokensList={toTokensList}
            onTokenChange={handleAssetUpdate(setToAsset, 'token')}
            onAmountChange={handleAssetUpdate(setToAsset, 'amount')}
          />
        </div>
        <div className="w-full">
          {!connected && (
            <Button
              loading={connecting}
              onClick={() => connectWalletToChain(fromChain?.chainId)}
            >
              Connect Wallet
            </Button>
          )}
          {connected && hasAllowance && (
            <Button
              loading={loading}
              disabled={+fromAmount === 0 || +toAmount === 0}
              onClick={handleSwap}
            >
              Swap
            </Button>
          )}
          {connected && !hasAllowance && (
            <Button
              loading={loading}
              onClick={handleApproval}
            >
              Approve
            </Button>
          )}
        </div>
      </div>
      <div className="hidden flex-1 pl-2 lg:flex lg:flex-col">
        <span className="text-base font-medium text-slate-500">
          {fromAsset?.token?.symbol}
          /
          {toAsset?.token?.symbol}
        </span>
        <div className="mt-2">
          <span className="text-xl font-semibold mr-2">2380.28</span>
          <span className="text-xs font-medium text-red-500">-26.68% (Past 1 month)</span>
        </div>
        <ResponsiveContainer width="100%" className="flex-1">
          <AreaChart
            data={graphData}
            margin={{ top: 10 }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="1%" stopColor="red" stopOpacity={0.5} />
                <stop offset="95%" stopColor="red" stopOpacity={0} />
              </linearGradient>
            </defs>
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="product"
              stroke="red"
              fillOpacity={1}
              fill="url(#colorUv)"
            />
          </AreaChart>
        </ResponsiveContainer>
        <div className="flex flex-row justify-end py-2 space-x-2">
          <Button
            className="w-12 !p-2 text-sm"
            onClick={() => setGraphData(data2)}
          >
            1D
          </Button>
          <Button
            className="w-12 !p-2 text-sm"
            onClick={() => setGraphData(data2)}
          >
            1W
          </Button>
          <Button
            className="w-12 !p-2 text-sm"
            onClick={() => setGraphData(data1)}
          >
            1M
          </Button>
        </div>
      </div>
    </div>
  );
}
