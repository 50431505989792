import React, {
  ChangeEvent,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { Close as CloseIcon } from '@mui/icons-material';
import { Entity } from 'models/entity';

interface AssetDialogProps {
  open: boolean;
  items: Entity[];
  type: 'chain' | 'token';
  title: string;
  onSelect: (entity: Entity) => void;
  onClose: () => void;
}

export default function AssetDialog({
  open,
  items,
  type,
  title,
  onSelect,
  onClose,
}: AssetDialogProps): ReactElement {
  const [search, setSearch] = useState('');
  const [listItems, setListItems] = useState<Entity[]>([]);

  useEffect(() => setListItems(items), [items]);

  const handleSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = event.target;
    if (value) {
      setListItems(
        items.filter((item: Entity) => item.name.toLowerCase().includes(value.toLowerCase())),
      );
    } else {
      setListItems(items);
    }
    setSearch(value);
  };

  const handleEntitySelect = (entity: Entity) => () => {
    onSelect(entity);
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      scroll="paper"
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 6,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <h2 className="text-md font-semibold px-4 py-3 text-center dark:bg-base-dark dark:text-white">
        {title ?? (type === 'chain' ? 'Switch to' : 'Select a token')}
      </h2>
      <DialogContent
        className="flex flex-col items-stretch overflow-y-hidden w-full max-h-[36rem] md:w-80 md:w-96 dark:bg-base-dark"
        sx={{ p: 0 }}
      >
        <input
          className="poly-input my-1 mx-4 border rounded-md p-2 dark:text-white focus:!border-primary"
          placeholder="Search Name"
          value={search}
          onChange={handleSearchInput}
        />
        <List className="overflow-y-scroll p-4">
          {listItems.map((item) => (
            <div
              key={item.id}
              className="border rounded-md my-2.5 mx-4 dark:bg-current dark:border-0 hover:border-slate-400"
            >
              <ListItem
                button
                onClick={handleEntitySelect(item)}
              >
                <Box
                  className="w-8 h-8 bg-white p-1 border-2 rounded-full"
                  component="img"
                  src={item.icon}
                  alt={item.name}
                  loading="lazy"
                />
                <div className="ml-2 flex flex-col items-start w-full">
                  <h3 className="text-sm leading-5 font-semibold uppercase dark:text-white">{item.name}</h3>
                  {type === 'token' && (
                    <p className="text-xs max-w-3xl text-left dark:text-white">
                      {item.symbol}
                    </p>
                  )}
                </div>
              </ListItem>
            </div>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
