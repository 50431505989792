import Decimal from 'decimal.js';
import { BigNumberish, ethers } from 'ethers';

export const convertsStrToDecimal = (num: string, decimals: number = 18): Decimal => {
  const [base, mantissa] = num.split('.');
  if (!mantissa) {
    return new Decimal(base.padEnd(decimals, '0'));
  }
  return new Decimal(`${base}${mantissa.padEnd(decimals, '0')}`);
};

export const formatBalance = (val: BigNumberish, decimals: number = 4): Decimal => {
  const [base, mantissa] = ethers.utils.formatEther(val).toString().split('.');
  if (!mantissa) {
    return new Decimal(base);
  }
  return new Decimal(`${base}.${mantissa.slice(0, decimals)}`);
};

export const formatEth = (val: BigNumberish): string => ethers.utils.formatEther(val).toString();

export const strToBytes32 = (val: string): string => ethers.utils.formatBytes32String(val);

export const convertPoolNameToSymbol = (name: string): string => {
  const symbol = name.replace(' ', '-').slice(0, 6);
  return symbol;
};
