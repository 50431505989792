import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';
import Swap from './pages/Swap';
import Pool from './pages/Pool';
import List from './pages/Pool/pages/List';
import Create from './pages/Pool/pages/Create';

export default function Router() {
  return (
    <Routes>
      <Route
        index
        element={<Swap />}
      />
      <Route
        path="pools"
        element={<Pool />}
      >
        <Route
          index
          element={<List />}
        />
        <Route
          path="new"
          element={<Create />}
        />
      </Route>
      <Route
        path="*"
        element={<Navigate to="/" />}
      />
    </Routes>
  );
}
