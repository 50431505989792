import {
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  convertsStrToDecimal,
  formatBalance,
  formatEth,
} from 'utils/helper';
import { Token } from 'models/token';
import { NotificationContext } from 'contexts/notification';
import { WalletContext } from 'contexts/wallet';

export default function useErc20(token: Token | undefined) {
  const { showNotification } = useContext(NotificationContext);
  const { address, getErc20Contract } = useContext(WalletContext);
  const [allowance, setAllowance] = useState<number>(0);

  const erc20Contract = useMemo(() => {
    if (token && getErc20Contract) {
      return getErc20Contract(token?.address);
    }
    return undefined;
  }, [getErc20Contract, token]);

  const fetchBalance = async (): Promise<string> => {
    if (!address || !erc20Contract) {
      return '';
    }
    const units = await erc20Contract?.balanceOf(address);
    return formatBalance(units).toNumber().toFixed(4).toLocaleString();
  };

  const fetchAllowance = async (pool: string): Promise<void> => {
    const units = await erc20Contract?.allowance(address, pool);
    const allowance = convertsStrToDecimal(formatEth(units), token?.decimals);
    setAllowance(+allowance);
  };

  const approveAmount = async (amount: string, pool: string): Promise<void> => {
    try {
      const tx = await erc20Contract?.approve(pool, amount);
      await tx.wait();
      await fetchAllowance(pool);
    } catch (err: any) {
      showNotification({
        message: `Approval failed due to ${err?.message}`,
      });
    }
  };

  return {
    allowance,
    approveAmount,
    fetchAllowance,
    fetchBalance,
  };
}
