import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Pool } from 'models/pool';
import usePools from 'hooks/usePools';
import { WalletContext } from 'contexts/wallet';
import clsx from 'clsx';
import PoolTokens from './components/PoolTokens';

export default function List() {
  const { pools = [] } = usePools();
  const { connected } = useContext(WalletContext);
  const createBtnClasses = clsx('poly-cta-btn ml-3', { visible: connected });

  return (
    <div className="flex-grow">
      <div className="flex mx-4 my-6">
        <h1 className="my-0 text-xl font-semibold">Investment Pools</h1>
        {connected && (
          <Link
            className={createBtnClasses}
            to="/pools/new"
          >
            <Add />
          </Link>
        )}
      </div>
      <Table>
        <colgroup>
          <col style={{ width: '40%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>
        <TableHead className="poly-bg">
          <TableRow>
            <TableCell>
              Composition
            </TableCell>
            <TableCell align="center">
              Volume (24h)
            </TableCell>
            <TableCell align="center">
              Value
            </TableCell>
            <TableCell align="center">
              APR
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {pools.map((pool: Pool) => (
            <TableRow
              key={pool.id}
              // className="cursor-pointer"
              sx={{
                '&:last-child td': {
                  border: 0,
                },
              }}
              // onClick={() => navigate(`/pools/${pool.id}`)}
            >
              <TableCell className="!pb-2">
                <PoolTokens tokens={pool.tokens} />
              </TableCell>
              <TableCell align="center">
                <span>
                  {`$${0}`}
                </span>
              </TableCell>
              <TableCell align="center">
                <span>
                  {`$${0}`}
                </span>
              </TableCell>
              <TableCell align="center">
                <span>
                  {`${0}`}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
