import { Chain } from './models/chain';

// eslint-disable-next-line import/prefer-default-export
export const chains: Chain[] = [
  {
    id: '42',
    chainId: 42,
    name: 'Kovan',
    icon: '/media/chains/kovan.png',
    symbol: 'KETH',
  },
  // {
  //   id: '1',
  //   chainId: 1,
  //   name: 'Ethereum',
  //   icon: '/media/chains/eth.svg',
  //   symbol: 'ETH',
  // },
  // {
  //   id: '56',
  //   chainId: 56,
  //   name: 'Binance',
  //   icon: '/media/chains/bnb.svg',
  // },
  // {
  //   id: '43114',
  //   chainId: 43114,
  //   name: 'Avalanche',
  //   icon: '/media/chains/avalanche.png',
  // },
];
