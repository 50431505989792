import React, {
  MouseEvent,
  ChangeEvent,
  ReactElement,
  useState,
} from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { SvgIconComponent } from '@mui/icons-material';
import Button from 'components/Button';
import useErc20 from 'hooks/useErc20';
import { PoolToken } from '../../models/pool';

interface AssetProps {
  poolCreationLoading: boolean;
  className?: string;
  MetaIcon?: SvgIconComponent;
  poolId: string;
  token: PoolToken;
  onChange: (token: PoolToken) => void;
  onRemove: () => void;
}

export default function AssetButton({
  poolCreationLoading,
  poolId,
  token,
  className,
  MetaIcon,
  onChange,
  onRemove,
}: AssetProps): ReactElement {
  const containerClasses = clsx('px-5 py-2 flex items-center !px-0', className);
  const {
    icon = '',
    name = '',
    symbol = '',
    isApproved,
  } = token;
  const [amount, setAmount] = useState<string>(token.amount ?? '');
  const [loading, setLoading] = useState<boolean>(false);
  const { approveAmount } = useErc20(token);

  const handleAmountUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const amount = event.target.value || '';
    setAmount(amount);
  };

  const approve = async () => {
    setLoading(true);
    await approveAmount(amount, poolId);
    setLoading(false);
    onChange({
      ...token,
      amount,
      isApproved: true,
    });
  };

  const handleDeleteClick = (event: MouseEvent<SVGElement>) => {
    event.stopPropagation();
    onRemove();
  };

  return (
    <Box
      className={containerClasses}
    >
      <Box
        className="w-6 h-6 bg-white p-1 rounded-full"
        component="img"
        src={icon}
        alt={name}
        loading="lazy"
      />
      <div className="ml-1 mr-2 flex flex-col items-start flex-grow w-full dark:text-white">
        <h3 className="text-sm leading-5 font-bold uppercase">
          {symbol}
        </h3>
      </div>
      {poolId && (
        <div className="py-1 px-3 bg-slate-100 rounded-md shadow-inner dark:bg-base-dark">
          <input
            className="flex-grow w-full text-sm poly-input font-medium mb-2 rounded-md sm:mr-4 sm:mb-0 dark:bg-base-dark"
            placeholder="0.0"
            type="number"
            step="0.01"
            disabled={loading || isApproved}
            value={amount}
            maxLength={16}
            onChange={handleAmountUpdate}
          />
        </div>
      )}
      {!poolId && MetaIcon && !poolCreationLoading && (
        <MetaIcon
          className="ml-2 rounded-full cursor-pointer text-red-600 hover:bg-red-50"
          fontSize="medium"
          onClick={handleDeleteClick}
        />
      )}
      {poolId && !isApproved && (
        <Button
          className="ml-2 poly-cta-btn font-semibold px-2 py-1 w-auto min-w-[5rem]"
          onClick={approve}
          loading={loading}
          disabled={+(amount || '0') <= 0}
        >
          Approve
        </Button>
      )}
    </Box>
  );
}
