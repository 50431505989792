import {
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  convertsStrToDecimal,
  formatEth,
  strToBytes32,
} from 'utils/helper';
import { Token } from 'models/token';
import { Pool } from 'models/pool';
import { Chain } from 'models/chain';
import { NotificationContext } from 'contexts/notification';
import usePools from 'hooks/usePools';
import { WalletContext } from 'contexts/wallet';

export type IAsset = {
  chain: Chain;
  token: Token | undefined;
  amount: string;
}

type SwapProps = {
  fromAsset: IAsset;
  toAsset: IAsset;
  isLocalSwap: boolean;
}

type InternalDrySwapProps = SwapProps & { tokenPools: Pool[] };

export default function useSwap() {
  const { showNotification } = useContext(NotificationContext);
  const { address, getContract } = useContext(WalletContext);

  const { pools = [] } = usePools();
  const [pool, setPool] = useState<Pool | undefined>();

  const contract = useMemo(() => getContract('SwapPoolTemplate'), [getContract]);

  const getLocalSwapAmount = async (args: InternalDrySwapProps, pool: Pool): Promise<number> => {
    const { token: fromToken, amount } = args.fromAsset;
    const { token: toToken } = args.toAsset;
    const contractInstance = contract?.attach(pool?.id);
    // eslint-disable-next-line no-await-in-loop
    const val = await contractInstance?.dry_swap_both(
      fromToken?.address,
      toToken?.address,
      amount,
    );
    return +formatEth(val);
  };

  const getCrossSwapAmount = async (args: InternalDrySwapProps, pool: Pool): Promise<number> => {
    const { token: fromToken, amount } = args.fromAsset;
    const { token: toToken } = args.toAsset;
    const contractInstance = contract?.attach(pool?.id);
    // eslint-disable-next-line no-await-in-loop
    const fromUnits = await contractInstance?.dry_swap_to_unitX64(fromToken?.address, amount);
    const formattedUnits = convertsStrToDecimal(formatEth(fromUnits), fromToken?.decimals);
    const toUnits = await contractInstance?.dry_swap_from_unitX64(toToken?.address, formattedUnits);
    return +formatEth(toUnits);
  };

  const calculateSwapUnits = async (args: InternalDrySwapProps): Promise<string> => {
    const fn = args.isLocalSwap ? getLocalSwapAmount : getCrossSwapAmount;
    if (pool) {
      const amount = await fn(args, pool);
      return amount.toString();
    }

    let maxAmount = 0;
    let bestPool;
    // eslint-disable-next-line no-restricted-syntax
    for (const pool of args.tokenPools) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const currAmount = await fn(args, pool);
        if (currAmount > maxAmount) {
          maxAmount = currAmount;
          bestPool = pool;
        }
      } catch {
      }
    }
    if (!bestPool) {
      const reason = 'No router found';
      showNotification({
        message: reason,
      });
      throw new Error(reason);
    }
    setPool(bestPool);
    return maxAmount.toString();
  };

  const drySwap = (args: SwapProps) => {
    const { token: fromToken } = args.fromAsset;
    const { token: toToken } = args.toAsset;
    const tokenPools = pools?.filter((pool: Pool) => {
      const { tokens = [] } = pool;
      return (
        tokens.includes((fromToken as Token).address)
        && tokens.includes((toToken as Token).address)
      );
    });
    const newArgs = { ...args, tokenPools };

    return calculateSwapUnits(newArgs);
  };

  const localSwap = async ({ fromAsset, toAsset }: SwapProps) => {
    const { token: fromToken, amount: fromAmount } = fromAsset;
    const { token: toToken } = toAsset;
    const formattedAmount = convertsStrToDecimal(fromAmount, fromToken?.decimals).toString();
    const minOutAmount = +fromAmount * 0;
    const minOut = convertsStrToDecimal(minOutAmount.toString(), fromToken?.decimals).toString();
    const contractInstance = contract?.attach(pool?.id as string);
    return contractInstance?.localswap(
      fromToken?.address,
      toToken?.address,
      formattedAmount,
      minOut,
    );
  };

  const crossChainSwap = async ({ fromAsset, toAsset }: SwapProps) => {
    const { token: fromToken, amount: fromAmount } = fromAsset;
    const { chain: toChain, token: toToken } = toAsset;
    const contractInstance = contract?.attach(pool?.id as string);
    const formattedAmount = convertsStrToDecimal(fromAmount, fromToken?.decimals).toString();
    const userAddressBytes = strToBytes32(address || '');
    return contractInstance?.outSwap(
      toChain?.chainId,
      pool?.id,
      fromToken?.address,
      toToken?.address,
      userAddressBytes,
      formattedAmount,
    );
  };

  const assetSwap = async (args: SwapProps) => {
    const fn = args.isLocalSwap ? localSwap : crossChainSwap;
    const tx = await fn(args);
    const result = await tx.wait();
    const isSuccess = result.status === 1;
    if (isSuccess) {
      showNotification({
        message: 'Assets swapped successfully',
        severity: 'success',
      });
    }
    return isSuccess;
  };

  const resetBestPool = () => {
    setPool(undefined);
  };

  return {
    pool,
    pools,
    drySwap,
    assetSwap,
    resetBestPool,
  };
}
