import React, {
  ReactElement,
} from 'react';
import clsx from 'clsx';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

interface StepsCardProps {
  className?: string;
}

const steps = ['Select Chain', 'Choose Tokens', 'Create Pool', 'Approve Token Amount'];

export default function StepsCard({ className }: StepsCardProps): ReactElement {
  const containerClasses = clsx('card flex flex-col !p-0 rounded-md w-[20rem] dark:bg-base-medium', className);

  return (
    <div className={containerClasses}>
      <div className="text-center border-b border-slate-200 p-4 dark:border-slate:500">
        <span className="text-base font-semibold">Create Pool Steps</span>
      </div>
      <Stepper activeStep={-1} orientation="vertical" className="p-4">
        {steps.map((step, index) => (
          <Step key={step}>
            <StepLabel>
              {step}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
