import React, { ReactElement } from 'react';

interface LogoProps {
  className?: string;
}

export default function Logo({ className }: LogoProps): ReactElement {
  return (
    <svg className={className} width="37" height="37" viewBox="0 0 37 37" fill="none">
      <rect x="1" y="1" width="8" height="8" stroke="currentColor" strokeWidth="2" />
      <rect x="28" y="1" width="8" height="8" stroke="currentColor" strokeWidth="2" />
      <rect x="1" y="28" width="8" height="8" stroke="currentColor" strokeWidth="2" />
      <rect x="28" y="28" width="8" height="8" stroke="currentColor" strokeWidth="2" />
      <rect x="9" y="4" width="19" height="2" fill="currentColor" />
      <rect x="9" y="31" width="19" height="2" fill="currentColor" />
      <rect x="4" y="28" width="19" height="2" transform="rotate(-90 4 28)" fill="currentColor" />
      <rect x="31" y="28" width="19" height="2" transform="rotate(-90 31 28)" fill="currentColor" />
    </svg>
  );
}
