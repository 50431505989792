import React, { ReactElement, useContext } from 'react';
import clsx from 'clsx';
import { LightMode, DarkMode } from '@mui/icons-material';
import { ContrastContext } from '../../contexts/contrast';

interface Props {
  className?: string;
}

export default function ContrastToggle({ className }: Props): ReactElement {
  const { isDark, toggleContrast } = useContext(ContrastContext);
  const contrastToggleClasses = clsx('relative w-16 h-8 rounded-md select-none', className, {
    'bg-black': !isDark,
    'text-white': !isDark,
    'bg-white': isDark,
    'text-black': isDark,
  });
  const bgClasses = clsx(
    'transition-transform ease-in-out duration-300 absolute top-1 left-1 rounded-md w-6 h-6 transform',
    {
      'translate-x-8': !isDark,
      'bg-black': isDark,
      'bg-white': !isDark,
    },
  );

  return (
    <button
      className={contrastToggleClasses}
      type="button"
      onClick={toggleContrast}
    >
      <div className="flex flex-row justify-around items-center h-full">
        <LightMode />
        <DarkMode />
      </div>
      <div className={bgClasses} />
      <div className="hidden text-white tex-black bg-white translate-x-8" />
    </button>
  );
}
