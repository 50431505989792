import React, {
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import usePools from 'hooks/usePools';
import Button from 'components/Button';
import { WalletContext } from 'contexts/wallet';
import { Pool } from './models/pool';
import CreatePoolCard from './components/CreatePoolCard';
import StepsCard from './components/PoolCreationStepsCard';
import { chains } from '../../../../mock';

export default function Create() {
  const { connected } = useContext(WalletContext);
  const navigate = useNavigate();
  const { refetchPools } = usePools();
  const [pools, setPools] = useState<Pool[]>([{
    id: '',
    name: '',
    tokens: [],
  }]);
  const [loading, setLoading] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const hasFreeChains = chains.length - pools.length > 0;

  const allowPoolAdd = useMemo(() => (
    !pools.length || pools.every((pool: Pool) => pool.chain)
  ), [pools]);

  const allowSubmit = useMemo(() => pools.every((pool: Pool) => pool.id), [pools]);

  const onPoolUpdate = (index: number) => (pool: Pool) => {
    const updatedPools = pools.map((_pool: Pool, i: number) => (
      index === i ? pool : _pool
    ));
    setPools(updatedPools);
  };

  const onPoolSelect = (index: number) => () => {
    ref?.current?.scrollTo({ top: 0 });
    setPools((pools: Pool[]) => [
      pools[index],
      ...pools.slice(0, index),
      ...pools.slice(index + 1),
    ]);
  };

  const addPool = () => {
    const pool: Pool = {
      id: '',
      name: '',
      tokens: [],
    };
    const updatedPools = [...pools, pool];
    setPools(updatedPools);
  };

  const removePool = (index: number) => () => {
    setPools((pools) => pools.filter((item: Pool, i: number) => index !== i));
  };

  const handleSubmit = () => {
    if (pools.length === 1) {
      refetchPools();
      navigate('/pools');
    }
  };

  return (
    <div className="flex flex-col flex-grow overflow-y-hidden justify-center items-start p-3 space-y-2 md:pt-12 md:space-x-4 md:space-y-0 md:flex-row">
      <StepsCard className="hidden md:flex" />
      <div
        ref={ref}
        className="flex flex-col items-center overflow-y-scroll h-full space-y-4 w-full md:w-auto flex-1 md:flex-none"
      >
        {pools.map((pool: Pool, index: number) => (
          <CreatePoolCard
            key={pool?.chain?.id}
            connected={connected}
            pools={pools}
            pool={pool}
            onChange={onPoolUpdate(index)}
            onCardClick={onPoolSelect(index)}
            onRemove={removePool(index)}
          />
        ))}
      </div>
      <div className="flex flex-col !p-0 rounded-md w-full space-y-4 md:w-[18rem] dark:bg-base-dark">
        <button
          type="button"
          className="poly-btn bg-white py-3 px-2"
          onClick={addPool}
          disabled={!allowPoolAdd || !hasFreeChains}
        >
          <Add className="mr-2 dark:text-black" />
          <span className="text-md font-semibold dark:text-black">Add Pool</span>
        </button>
        <Button
          loading={loading}
          className="text-md poly-cta-btn font-bold py-3 px-2"
          onClick={handleSubmit}
          disabled={!connected || !allowSubmit}
        >
          {pools.length > 1 ? 'Connect Pools' : 'Submit Pool'}
        </Button>
      </div>
    </div>
  );
}
