import React, {
  createContext,
  ReactChild,
  useCallback, useEffect,
  useMemo,
  useState,
} from 'react';

interface ContrastProviderProps {
  children: ReactChild;
}

export const ContrastContext = createContext({ isDark: false, toggleContrast: () => {} });

export default function ContrastProvider({ children }: ContrastProviderProps) {
  const [isDark, setDarkMode] = useState(false);

  const toggleContrast = useCallback((): void => {
    setDarkMode((isDarkModeOn: boolean) => {
      const updatedContrast = !isDarkModeOn;
      localStorage.theme = updatedContrast ? 'dark' : 'light';
      return updatedContrast;
    });
  }, []);

  const contrastValues = useMemo(() => ({ isDark, toggleContrast }), [isDark, toggleContrast]);

  useEffect(() => {
    const isDarkModeOn = (
      localStorage.theme === 'dark'
      || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
    );
    setDarkMode(isDarkModeOn);
  }, []);

  useEffect(() => {
    if (isDark) {
      document.body.classList.add('dark', 'poly-base-dark');
    } else {
      document.body.classList.remove('dark', 'poly-base-dark');
    }
  }, [isDark]);

  return (
    <ContrastContext.Provider
      value={contrastValues}
    >
      {children}
    </ContrastContext.Provider>
  );
}
