import React, { useContext } from 'react';
import clsx from 'clsx';
import { KeyboardArrowDown, SvgIconComponent } from '@mui/icons-material';
import { Entity } from 'models/entity';
import { AssetDialogContext } from 'contexts/assetDialog';

interface Props {
  className?: string;
  dialogTitle: string;
  MetaIcon?: SvgIconComponent;
  item: Entity;
  type: 'chain' | 'token';
  options: Entity[];
  onChange: (item: Entity) => void;
}

export default function StyledSelectButton({
  className,
  item,
  type,
  options,
  dialogTitle,
  MetaIcon = KeyboardArrowDown,
  onChange,
}: Props) {
  const { openDialog } = useContext(AssetDialogContext);
  const classes = clsx('flex items-center p-2 rounded-md font-semibold dark:bg-base-dark', className);

  const openSelectDialog = () => openDialog({
    type,
    items: options,
    title: dialogTitle,
    onSelect: (entity: Entity) => onChange(entity),
  });

  return (
    <button
      type="button"
      className={classes}
      onClick={openSelectDialog}
    >
      <img
        className="w-5 h-5 mr-2 rounded-full"
        src={item?.icon}
        alt={item?.name}
        loading="lazy"
      />
      <h3 className="text-sm leading-5 font-bold uppercase">
        {type === 'chain' ? item?.name : item?.symbol}
      </h3>
      <MetaIcon
        className="ml-1 poly-neutral rounded-full dark:poly-neutral-dark"
        fontSize="small"
      />
    </button>
  );
}
