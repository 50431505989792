import { ContractInterface } from 'ethers';
import deploymentMap from './map.json';

type DeploymentMap = {
  [networkId: string]: {
    [name: string]: string;
  };
};

const loadFile = async (
  name: string,
  networkId: number,
): Promise<[string, ContractInterface]> => {
  // eslint-disable-next-line import/no-dynamic-require,global-require
  const content: ContractInterface = await require(`../deployments/${networkId}/${name}.json`);
  return [name, content];
};

export type AbiInfo = {
  abi: ContractInterface;
  address: string;
};

// eslint-disable-next-line import/prefer-default-export
export const getAbis = async (networkId: number): Promise<Record<string, AbiInfo>> => {
  const currentDeployments: Record<string, string> = (deploymentMap as DeploymentMap)[networkId];
  const promises = Object.keys(currentDeployments).map((name: string) => loadFile(name, networkId));
  return Promise.all(promises).then((values) => {
    const abis: Record<string, AbiInfo> = values.reduce((map, abi) => {
      const [name, content] = abi;

      return {
        ...map,
        [name]: {
          abi: content,
          address: currentDeployments[name],
        },
      };
    }, {});
    return abis;
  });
};
