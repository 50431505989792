import React, {
  ReactChild,
  useContext,
  useMemo,
} from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { ContrastContext } from './contrast';

interface ProviderProps {
  children: ReactChild;
}

export default function MuiTheme({ children }: ProviderProps) {
  const { isDark } = useContext(ContrastContext);
  const theme = useMemo(() => createTheme({
    palette: {
      mode: isDark ? 'dark' : 'light',
      success: {
        light: '#93DCC6',
        main: '#74D2B6',
        dark: '#3CB993',
      },
    },
  }), [isDark]);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}
