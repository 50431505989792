import React, {
  ReactChild,
  ReactElement,
} from 'react';
import clsx from 'clsx';
import { CircularProgress } from '@mui/material';

interface ContainerProps {
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
  children: ReactChild | ReactChild[];
}

export default function Button({
  loading,
  disabled,
  className,
  onClick = () => {},
  children,
}: ContainerProps): ReactElement {
  const classes = clsx('p-4 poly-cta-btn font-bold self-center w-full flex justify-center items-center w-full', className);

  return (
    <button
      className={classes}
      type="button"
      disabled={loading || disabled}
      onClick={onClick}
    >
      {loading && <CircularProgress size={24} color="inherit" />}
      {!loading && children}
    </button>
  );
}
