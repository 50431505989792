import React, {
  ChangeEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react';
import clsx from 'clsx';
import useErc20 from 'hooks/useErc20';
import { Entity } from 'models/entity';
import { Token } from 'models/token';
import { convertsStrToDecimal } from 'utils/helper';
import { WalletContext } from 'contexts/wallet';
import StyledSelectButton from '../SelectButton';

interface AssetCardProps {
  className?: string;
  token: Token | undefined;
  amount: string;
  onTokenChange: (token: Entity) => void;
  onAmountChange: (amount: string) => void;
  tokensList: Entity[];
  sourceAsset?: boolean;
}

export default function AssetCard({
  token,
  onTokenChange,
  amount,
  onAmountChange,
  className,
  tokensList,
  sourceAsset,
}: AssetCardProps): ReactElement {
  const { connected } = useContext(WalletContext);
  const containerClasses = clsx('flex flex-col', className);
  const [balance, setBalance] = useState<string>('');
  const { fetchBalance } = useErc20(token);

  const handleAmountUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    const { value = '' } = event.target;
    onAmountChange(value);
  };

  // @ts-ignore
  useEffect(async () => {
    if (sourceAsset && connected) {
      const balance = await fetchBalance();
      setBalance(balance);
    }
  }, [
    sourceAsset,
    connected,
    fetchBalance,
  ]);

  return (
    <div className={containerClasses}>
      <div className="py-4 px-3 bg-slate-100 rounded-md shadow-inner dark:bg-base-dark">
        <div className="flex flex-row justify-between items-center">
          <span
            className="text-xs font-semibold"
          >
            {sourceAsset ? 'Send:' : 'Receive:'}
          </span>
          {sourceAsset && balance && (
            <button
              className="text-xs font-semibold underline text-slate-500"
              type="button"
              onClick={() => {
                const formattedBalance = convertsStrToDecimal(balance, token?.decimals);
                onAmountChange(formattedBalance.toString());
              }}
            >
              {`Max: ${balance}`}
            </button>
          )}
        </div>
        <div className="flex flex-row justify-center">
          <input
            className="flex-grow w-full text-4xl poly-input font-semibold mr-2 mb-2 rounded-md sm:mr-4 sm:mb-0 dark:bg-base-dark"
            placeholder="0.0"
            type="number"
            step="0.01"
            min={0}
            value={amount}
            disabled={!sourceAsset}
            onChange={handleAmountUpdate}
          />
          <StyledSelectButton
            className="justify-around disabled:opacity-50 w-auto !px-1 text-sm"
            type="token"
            options={tokensList}
            item={token as Token}
            onChange={onTokenChange}
            dialogTitle={sourceAsset ? 'Source Token' : 'Target Token'}
          />
        </div>
      </div>
    </div>
  );
}
