import {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useQuery } from 'react-query';
import { getTokens } from '../services/api';

export default function useTokens() {
  const {
    data,
    isLoading: loading,
    refetch,
  } = useQuery('tokens', getTokens, { enabled: false });
  const tokens = useMemo(() => (
    (data || []).map((token: any) => ({
      ...token,
      id: token.address,
      icon: token.logoURI,
    }))
  ), [data]);
  const tokensMapping = useMemo(() => (
    tokens.reduce((map, token: any) => ({
      ...map,
      [token.address]: token,
    }), {})
  ), [tokens]);
  const chainsMapping = useMemo(() => (
    tokens.reduce((map: { [id: number]: string[] }, token) => {
      const { chainId, address } = token;
      return {
        ...map,
        [chainId]: [...(map[chainId] || []), address],
      };
    }, {})
  ), [tokens]);

  const getTokensByChain = useCallback((chainId: number) => {
    const tokenAddresses = chainsMapping[chainId] || [];
    return tokenAddresses.map((address: string) => tokensMapping[address]);
  }, [chainsMapping, tokensMapping]);

  const getTokenByAddress = useCallback(
    (address: string) => tokensMapping[address],
    [tokensMapping],
  );

  useEffect(() => {
    if (!loading && !data) {
      refetch();
    }
  }, [loading, data]);

  return {
    tokens,
    loading,
    chainsMapping,
    getTokensByChain,
    getTokenByAddress,
  };
}
