export type ConfigType = {
  eth: {
    network: {
      [id: number]: {
        name: string;
        provider: string;
      };
    };
  };
};

export const PROVIDERS_CONFIG: ConfigType = {
  eth: {
    network: {
      1: {
        name: 'homestead',
        provider: 'https://mainnet.infura.io/v3/22d6f3f0834446c5b873990bbd7203e3',
      },
      42: {
        name: 'kovan',
        provider: 'https://eth-kovan.alchemyapi.io/v2/zlARr7cK5mbd761gMH2xvjAOglNYCZqJ',
      },
    },
  },
};
