import React, {
  MouseEvent,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';
import { Delete } from '@mui/icons-material';
import useTokens from 'hooks/useTokens';
import Button from 'components/Button';
import { chains as chainsData } from 'mock';
import { Entity } from 'models/entity';
import { AssetDialogContext } from 'contexts/assetDialog';
import { Chain } from 'models/chain';
import AssetButton from 'pages/Pool/pages/Create/components/AssetButton';
import { NotificationContext } from 'contexts/notification';
import useRefState from 'hooks/useRefState';
import { Pool, PoolToken } from '../../models/pool';
import useCreatePool from '../../hooks/useCreatePool';

interface Props {
  className?: string;
  connected: boolean;
  pools: Pool[];
  pool: Pool;
  onChange: (pool: Pool) => void;
  onCardClick: () => void;
  onRemove: () => void;
}

export default function CreatePoolCard({
  className,
  connected,
  pools,
  pool,
  onChange,
  onCardClick,
  onRemove,
}: Props): ReactElement {
  const { showNotification } = useContext(NotificationContext);
  const { openDialog } = useContext(AssetDialogContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [newPool, setNewPool, newPoolRef] = useRefState<Pool>(pool);
  const {
    id,
    chain,
    name = '',
    tokens = [],
  } = newPool ?? {};

  const { getTokensByChain } = useTokens();
  const createPool = useCreatePool();
  const selectedChainIds = useMemo(() => pools.map(({ chain }: Pool) => chain?.id), [pools]);
  const selectedTokenIds = useMemo(() => tokens.map(({ id }: PoolToken) => id), [tokens]);
  const containerClasses = clsx('card flex flex-col p-4 rounded-md w-full md:w-[27rem] md:first:w-[30rem] first:drop-shadow-lg dark:bg-base-medium', className);

  const allowDelete = useMemo(() => (
    !id && !loading && pools.length > 1
  ), [id, loading, pools]);

  const openChainSelectDialog = () => {
    const allowedChains = chainsData.filter((chain: Entity) => (
      !selectedChainIds.includes(chain.id)
    ));
    openDialog({
      type: 'chain',
      items: allowedChains,
      onSelect: (entity) => {
        const pool: Pool = {
          ...newPool,
          chain: entity as Chain,
        };
        setNewPool(pool);
        onChange(pool);
      },
    });
  };

  const openTokenSelectDialog = () => {
    const tokensData = getTokensByChain(+(chain?.id || 0));
    const allowedTokens = tokensData.filter((token: Entity) => (
      !selectedTokenIds.includes(token.id)
    ));
    openDialog({
      type: 'token',
      items: allowedTokens,
      onSelect: (entity) => {
        setNewPool({
          ...newPool,
          tokens: [...tokens, entity as PoolToken],
        });
      },
    });
  };

  const handleRemoveToken = (index: number) => () => {
    const updatedTokens = tokens.filter((token, _index) => index !== _index);
    setNewPool({ ...newPool, tokens: updatedTokens });
  };

  const handleCreatePool = async () => {
    setLoading(true);
    try {
      const poolId: string = await createPool(newPool);
      setNewPool({
        ...newPool,
        id: poolId,
      });
    } catch {
      showNotification({ message: 'Pool creation failed' });
    } finally {
      setLoading(false);
    }
  };

  const handleTokenUpdate = (index: number) => (token: PoolToken) => {
    const { tokens } = newPoolRef.current;
    const updatedTokens = tokens.map((_token: PoolToken, i: number) => (
      i === index ? token : _token
    ));
    setNewPool({
      ...newPool,
      tokens: updatedTokens,
    });
  };

  const handleDeleteClick = (event: MouseEvent<SVGElement>) => {
    event.stopPropagation();
    onRemove();
  };

  useEffect(() => {
    if (newPool.tokens.length >= 1) {
      const proceed = newPool.tokens.every(({ isApproved }: PoolToken) => isApproved);
      if (proceed) {
        onChange(newPool);
      }
    }
  }, [newPool]);

  return (
    // eslint-disable-next-line
    <div
      id="pool-card"
      className={containerClasses}
      onClick={onCardClick}
    >
      <div className="flex justify-between items-center">
        {chain && (
          <button
            type="button"
            className="text-sm mb-1 font-semibold"
            onClick={openChainSelectDialog}
          >
            {`${chain?.name} Network`}
          </button>
        )}
        {!chain && (
          <button
            type="button"
            className="text-sm mb-1 font-semibold underline"
            onClick={openChainSelectDialog}
          >
            Select Chain
          </button>
        )}
        {allowDelete && (
          <Delete
            className="text-red-600 hover:bg-red-50 rounded-full cursor-pointer"
            onClick={handleDeleteClick}
          />
        )}
      </div>
      {chain && (
        <div className="py-2 px-3 bg-slate-100 rounded-md shadow-inner dark:bg-base-dark my-2">
          <span className="text-xs font-semibold">Enter Pool Name:</span>
          <input
            className="flex-grow w-full text-1xl poly-input font-medium mr-2 mb-2 rounded-md sm:mr-4 sm:mb-0 dark:bg-base-dark"
            placeholder={`Enter Pool Name, e.g. PS_${chain?.symbol}`}
            type="text"
            value={name}
            maxLength={16}
            disabled={!!id || loading}
            onChange={(event) => setNewPool({
              ...newPool,
              name: event.target.value,
            })}
          />
        </div>
      )}
      {chain && (
        <>
          <span className="text-lg font-bold dark:text-gray-300 my-2">
            Tokens
          </span>
          <div className="flex flex-col">
            {tokens.map((token: PoolToken, index) => (
              <AssetButton
                poolCreationLoading={loading}
                key={token.id}
                token={token}
                poolId={id}
                className="!justify-start mb-1 md:mb-0 !w-full"
                MetaIcon={Delete}
                onChange={handleTokenUpdate(index)}
                onRemove={handleRemoveToken(index)}
              />
            ))}
            {tokens?.length < 3 && (
            <button
              type="button"
              className="text-sm mb-1 font-semibold underline text-left my-2"
              onClick={openTokenSelectDialog}
            >
              Select Token
            </button>
            )}
          </div>
        </>
      )}
      {!id && (
        <Button
          className="mt-2 w-full"
          loading={loading}
          disabled={!connected || !name || !chain || !tokens.length}
          onClick={handleCreatePool}
        >
          Create Pool
          {' '}
          {chain ? `on ${chain?.name}` : ''}
        </Button>
      )}
    </div>
  );
}
