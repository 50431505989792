import { useContext } from 'react';
import { convertPoolNameToSymbol } from 'utils/helper';
import { WalletContext } from 'contexts/wallet';
import deployment from 'deployments/map.json';
import { ethers } from 'ethers';
import { Pool } from '../models/pool';

export default function useCreatePool() {
  const { getContract } = useContext(WalletContext);
  const SwapPoolFactory = getContract('SwapPoolFactory');

  const createPool = async ({ name, chain, tokens }: Pool) => {
    const mappedTokens = new Array(3)
      .fill('0x0000000000000000000000000000000000000000')
      .map((val, index) => tokens[index]?.id ?? val);
    const symbol = convertPoolNameToSymbol(name || '');
    const tx = await SwapPoolFactory?.deploy_swappool(
      (deployment as any)[chain?.id ?? ''].CrossChainSwapInterface,
      mappedTokens,
      4,
      name,
      symbol,
      { gasLimit: 1200000 },
    );
    const result = await tx.wait();
    const event = result?.events?.find((event: any) => event.event === 'PoolDeployed');
    return ethers.utils.hexStripZeros(event.topics[2]);
  };

  return createPool;
}
