import React, {
  ReactElement,
  useContext,
} from 'react';
import clsx from 'clsx';
import CircularProgress from '@mui/material/CircularProgress';
import { WalletContext } from '../../../contexts/wallet';
import { NotificationContext } from '../../../contexts/notification';

export default function WalletInfo(): ReactElement {
  const {
    address,
    connected,
    connect,
    connecting,
  } = useContext(WalletContext);
  const { showNotification } = useContext(NotificationContext);
  const connectionBtn = clsx(
    'box poly-pri-btn flex flex-row items-center align-center font-semibold whitespace-nowrap text-ellipsis overflow-hidden px-3 py-1 rounded-md',
    {
      'w-min': true,
    },
  );

  const connectWallet = () => {
    connect()
      .then(() => showNotification({ message: 'Wallet Connected', severity: 'success' }))
      .catch((err) => showNotification({ message: err }));
  };

  return (
    <div className="flex flex-row space-x-3">
      <button
        className={connectionBtn}
        type="button"
        onClick={connectWallet}
      >
        {address && `${address?.slice(0, 6)}...${address?.slice(-4)}`}
        {!connecting && !connected && 'Connect Wallet'}
        {connecting && <CircularProgress size={18} color="inherit" />}
      </button>
    </div>
  );
}
