import React, {
  MouseEvent, ReactElement, useContext, useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import {
  Cable,
  DarkMode,
  LightMode,
  MoreHoriz,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import Logo from '../Logo';
import WalletInfo from './WalletInfo';
import ContrastToggle from '../ContrastToggle';
import { ContrastContext } from '../../contexts/contrast';

interface Props {
  className?: string;
}

function MobileMenu({ className }: Props) {
  const navigate = useNavigate();
  const { isDark, toggleContrast } = useContext(ContrastContext);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = !!anchorEl;

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPool = () => {
    navigate('/pools');
  };

  return (
    <Box className={className}>
      <IconButton
        className="poly-sec-btn"
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiMenu-list': {
              p: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={openPool}>
          <ListItemIcon sx={{ minWidth: '1.75rem !important' }}>
            <Cable fontSize="small" />
          </ListItemIcon>
          Pool
        </MenuItem>
        <MenuItem onClick={toggleContrast}>
          <ListItemIcon sx={{ minWidth: '1.75rem !important' }}>
            {isDark && <LightMode fontSize="small" />}
            {!isDark && <DarkMode fontSize="small" />}
          </ListItemIcon>
          {isDark && 'Light Mode'}
          {!isDark && 'Dark Mode'}
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default function Navbar(): ReactElement {
  return (
    <nav className="flex flex-row justify-between items-center w-full px-3 py-4 shadow-md bg-white dark:poly-neutral-dark md:px-6">
      <Link to="/" className="flex flex-row items-center">
        <Logo className="w-6 h-6 md:mr-2" />
        <h1 className="font-bold text-xl hidden md:block">PolySwap</h1>
      </Link>

      <div className="flex flex-row space-x-3 md:space-x-6">
        <Link
          to="/pools"
          className="hidden items-center md:flex"
        >
          <span className="text-lg font-semibold">Pools</span>
        </Link>
        <WalletInfo />
        <ContrastToggle className="hidden md:block" />

        <MobileMenu className="block md:hidden" />
      </div>
    </nav>
  );
}
