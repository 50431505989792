import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

export default function useRefState<T>(
  initialValue: T,
): [T, Dispatch<SetStateAction<T>>, MutableRefObject<T>] {
  const [value, dispatcher] = useState<T>(initialValue);
  const ref = useRef<T>(value);

  useEffect(() => {
    if (ref) {
      ref.current = value;
    }
  }, [value]);

  return [value, dispatcher, ref];
}
