import React, {
  createContext,
  ReactChild,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  providers,
  BigNumber,
  Contract,
} from 'ethers';
import Web3Modal from 'web3modal';
import { ContrastContext } from './contrast';
import erc20Abi from '../deployments/erc20.json';
import { AbiInfo, getAbis } from '../deployments';
import { PROVIDERS_CONFIG } from '../config/network';

type Providers = providers.Web3Provider | providers.JsonRpcProvider;

type ContrastProviderProps = {
  children: ReactChild;
};

interface WalletContextValues {
  address: string;
  connect: (networkId?: number) => Promise<any>;
  connected: boolean;
  connecting: boolean;
  getContract: (name: string, address?: string) => Contract | undefined;
  getErc20Contract: (address: string) => Contract | undefined;
}

export const WalletContext = createContext<WalletContextValues>({
  address: '',
  connect: () => Promise.resolve(),
  connected: false,
  connecting: false,
  getContract: (name) => undefined,
  getErc20Contract: (address) => undefined,
});

export default function WalletProvider({ children }: ContrastProviderProps) {
  const { isDark } = useContext(ContrastContext);
  const defaultNetwork = 42;
  const [networkId, setNetwork] = useState(defaultNetwork);
  const [connecting, setConnecting] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');
  const defaultProvider = useMemo(() => (
    new providers.JsonRpcProvider(PROVIDERS_CONFIG.eth.network[networkId].provider)
  ), [networkId]);
  const [provider, setProvider] = useState<Providers>(defaultProvider);
  const [signer, setSigner] = useState<providers.JsonRpcSigner>();
  const [contracts, setContracts] = useState<Record<string, Contract>>({});

  const connected = useMemo(() => !!address && !!provider, [address, provider]);

  const web3Modal = useMemo(() => {
    const providerOptions = {};

    return new Web3Modal({
      network: 'mainnet',
      cacheProvider: true,
      theme: isDark ? 'dark' : 'light',
      providerOptions,
    });
  }, [isDark]);

  const targetChain = async (instance: any, chainId: number) => {
    const chainIDhex = BigNumber.from(chainId.toString()).toHexString();
    try {
      return await instance.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: chainIDhex }],
      });
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          return await instance.request({
            method: 'wallet_addEthereumChain',
            params: [{ chainId: chainIDhex, rpcUrl: '' }],
          });
        } catch {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
    return false;
  };

  const handleConnection = async (instance: any) => {
    const provider = new providers.Web3Provider(instance, 'any');
    const signer = provider?.getSigner();
    const chainId = await signer?.getChainId();
    if (chainId === networkId) {
      setProvider(provider);
      setSigner(signer);
      await provider.ready;
      signer.getAddress().then((value: string) => setAddress(value));
    } else {
      setProvider(defaultProvider);
      setSigner(undefined);
      throw Error('Wrong network');
    }
  };

  const onUserProviderChange = async (instance: any, soft?: boolean) => {
    if (!soft) {
      await targetChain(instance, networkId);
    }
    await handleConnection(instance);
  };

  const connect = useCallback(async (networkId: number = defaultNetwork) => {
    setConnecting(true);
    setNetwork(networkId);
    const provider = await web3Modal.connect();

    try {
      await targetChain(provider, networkId);
      await provider.send('eth_requestAccounts');
      await handleConnection(provider);
    } catch (e) {
      console.log('Could not get a wallet connection', e);
      throw e;
    } finally {
      setConnecting(false);
    }

    if (!provider.on) {
      return null;
    }

    // Subscribe to account change event
    provider.on('accountsChanged', () => onUserProviderChange(provider));

    // Subscribe to chainId change event
    provider.on('chainChanged', () => onUserProviderChange(provider));

    await onUserProviderChange(provider, true);

    return () => {
      // Unsubscribe from account change event
      provider.removeListener('accountsChanged', () => onUserProviderChange(provider));

      // Unsubscribe from chain change event
      provider.removeListener('chainChanged', () => onUserProviderChange(provider));
    };
  }, []);

  const getErc20Contract = (address: string): Contract => {
    const manager = signer ?? provider;
    return new Contract(address, erc20Abi, manager);
  };

  const getContract = (name: string): Contract => contracts[name];

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, [web3Modal]);

  // @ts-ignore
  useEffect(async () => {
    if (connected) {
      const abis: Record<string, AbiInfo> = await getAbis(networkId);
      const contracts: Record<string, Contract> = Object.entries(abis).reduce((map, item) => {
        const [name, { abi, address }] = item;
        const manager = signer ?? provider;
        const contract: Contract = new Contract(address, abi, manager);
        return {
          ...map,
          [name]: contract,
        };
      }, {});
      setContracts(contracts);
    }
  }, [
    connected,
    networkId,
    signer,
    provider,
  ]);

  const values = useMemo(() => ({
    address,
    connect,
    connected,
    connecting,
    getErc20Contract,
    getContract,
  }), [
    address,
    connect,
    connected,
    connecting,
    getErc20Contract,
    getContract,
  ]);

  return (
    <WalletContext.Provider
      value={values}
    >
      {children}
    </WalletContext.Provider>
  );
}
