import React from 'react';
import ContrastProvider from './contexts/contrast';
import WalletProvider from './contexts/wallet';
import AssetDialogProvider from './contexts/assetDialog';
import MuiTheme from './contexts/muiTheme';
import Navbar from './components/Navbar';
import Router from './router';
import NotificationProvider from './contexts/notification';

export default function App() {
  return (
    <NotificationProvider>
      <ContrastProvider>
        <WalletProvider>
          <AssetDialogProvider>
            <MuiTheme>
              <div className="app h-max flex flex-col min-h-full h-full">
                <Navbar />
                <Router />
              </div>
            </MuiTheme>
          </AssetDialogProvider>
        </WalletProvider>
      </ContrastProvider>
    </NotificationProvider>
  );
}
